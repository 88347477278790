<template>
  <PageLayout>
    <template #default>

      <div class="container">
        <div class="row">
          <div class="col-sm">
            <vue-carousel :data="data"></vue-carousel>
          </div>
          
        </div>
        <div class="mx-auto text-start">
          <br />
          <br />
          <p> Save yourself time, money and frustration with the <strong>ShoeSecure® horseshoe shield</strong> - an
            innovation in horseshoe loss prevention and heel protection.&nbsp;</p>
          <p style="text-align: center;"><span style="text-decoration: underline; font-weight: bold;">Features</span></p>
          <p>Shoe loss is a costly and potentially dangerous concern for any horse owner - risks of damage to the hoof
            from direct injury or standing on cast shoes in the field can result in mounting vet and farrier bills, not to
            mention the impact on training regimes or competition targets. ShoeSecure prevents shoe loss and injuries
            associated with over-reaching by protecting the heels of the horse and its shoes. Designed from an experienced
            equestrian background and precision crafted for an exceptional product. Constructed in durable and lightweight
            thermoplastic polymer, ShoeSecure provides many months of use. Fitting is quick and easy with two bespoke
            SupaStuds with all the patented SupaStud features. Fit before turn out then remove on return to the stable -
            rinse to clean. ShoeSecure comes in 4 colours and two sizes accommodating shoes from 10 - 16.5cm in diameter.
          </p>
          <ul>
            <li>Prevents shoe loss and hoof damage from over-reaching</li>
            <li>Accommodates a range of horseshoe sizes, including remedial shoes.</li>
            <li>Quick and Easy to fit using two bespoke SupaStuds</li>
            <li>Re-usable on successive shoes.</li>
            <li>Durable, flexible and light weight giving months of use</li>
            <li>Highly refined product design crafted to maximise protection without impacting performance or gait</li>
            <li>Allows the farrier to properly balance the shoe on the hoof providing adequate support for the heels</li>
            <li>Allows freedom of airflow to avoid risk of infection or fungal problems</li>
          </ul>
          <p class="text-center"><img src="@/assets/img/sizechart2.png"
              width="260">&nbsp;&nbsp;&nbsp;&nbsp;<img src="@/assets/img/award.png" alt="Business of the Year Award"></p>
        </div>
      </div>
    </template>
</PageLayout></template>

  <!-- <script setup>

  import PageLayout from '@/components/page-layout';

  </script> -->

<script>
import PageLayout from '@/components/page-layout';

export default {
  components: { PageLayout },
  data() {
    return {
      data: [
        '<div id="panel1" class="slide-item"></div>',
        '<div id="panel2" class="slide-item"></div>',
        '<div id="panel3" class="slide-item"></div>',
        '<div id="panel4" class="slide-item"></div>',
        '<div id="panel5" class="slide-item"></div>',
        '<div id="panel6" class="slide-item"></div>',
        '<div id="panel7" class="slide-item"></div>',
      ],
    };
  },
};
</script>
<style>
.slide-item {
  background-repeat:no-repeat;
  background-position: center;
  align-items: center;
  color: #999;
  display: flex;
  font-size: 1.5rem;
  justify-content: center;
  min-height: 293px;
}
#panel1 {background-image:url('@/assets/img/front1.png');}
#panel2 {background-image:url('@/assets/img/front2.png');}
#panel3 {background-image:url('@/assets/img/front3.png');}
#panel4 {background-image:url('@/assets/img/front4.png');}
#panel5 {background-image:url('@/assets/img/front5.png');}
#panel6 {background-image:url('@/assets/img/front6.png');}
#panel7 {background-image:url('@/assets/img/front7.png');}
</style>