import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../views/HomePage.vue'
import AboutPage from '../views/AboutPage.vue'
import TheProblem from '../views/TheProblem.vue'
import TheSolution from '../views/TheSolution.vue'
import TheResearch from '../views/TheResearch.vue'
import FittingSizing from '../views/FittingSizing.vue'

import BuyShoesecure from '../views/BuyShoesecure.vue'
import ReviewsPage from '../views/CustomerReviews.vue'
import DemonstrationVideo from '../views/DemonstrationVideo.vue'
import InformationPage from '../views/InformationPage.vue'
import TermsConditions from '../views/TermsConditions.vue'
import StockistPage from '../views/StockistPage.vue'
import ContactUs from '../views/ContactUs.vue'


const routes = [
    {
      path: '/',
      name: 'home',
      component: HomePage
    },
    {
        path: '/about',
        name: 'about',
        component: AboutPage
    },
    {
        path: '/contactus',
        name: 'contactus',
        component: ContactUs
    },
    {
        path: '/problem',
        name: 'problem',
        component: TheProblem
    },
    {
        path: '/solution',
        name: 'solution',
        component: TheSolution
    },
    {
        path: '/research',
        name: 'research',
        component: TheResearch
    },
    {
        path: '/fitting',
        name: 'fitting',
        component: FittingSizing
    },
    {
        path: '/buyshoesecure',
        name: 'buyshoesecure',
        component: BuyShoesecure
    },
    {
        path: '/reviews',
        name: 'reviews',
        component: ReviewsPage
    },
    {
        path: '/demonstration',
        name: 'demonstration',
        component: DemonstrationVideo
    },
    {
        path: '/information',
        name: 'information',
        component: InformationPage
    },
    {
        path: '/terms',
        name: 'terms',
        component: TermsConditions
    },
    {
        path: '/stockist',
        name: 'stockist',
        component: StockistPage
    },
    {
        path: '/buyshoesecure',
        name: 'buyshoesecure',
        component: BuyShoesecure
    }

  ]
  
  const router = createRouter({
    history: createWebHistory(),
    routes
  })
  
  export default router